<template>
  <div class="allowed-user-settings-form">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" />
      <div v-else class="allowed-user-settings-form__content">
        <div class="l-col">
          <label v-html="fields.wmvmrros.label"></label>
        </div>

        <div class="l-flex-1_sm-1-gutter-4">
          <div class="l-col">
            <div class="l-col">
              <base-input
                :value="formData.vmrros_date"
                :tooltip="currentDate"
                :type="fields.vmrros_date.type"
                placeholder="сегодняшняя дата в формате ддммгггг"
                :name="fields.vmrros_date.name"
                @input="onChange('vmrros_date', $event)"
              >
                {{ $t('fields.date') }}
              </base-input>
            </div>
            <div class="l-col">
              <base-input
                :value="fields.new_pass.value"
                tooltip="Сгенерированный системой пароль"
                :type="fields.new_pass.type"
                :name="fields.new_pass.name"
                :disabled="true"
              >
                <template #fieldAfter>
                  <plain-button
                    :icon="fields.new_pass.type === 'text' ? 'eye-closed' : 'eye-open'"
                    color="dark"
                    tabindex="-1"
                    @click="showPassword(fields.new_pass)"
                  />
                </template>
                {{ $t('fields.pass') }}
              </base-input>
            </div>
            <div class="l-col">
              <base-select
                v-model="os"
                name="os"
                placeholder="Выберите ОС для установки"
                :options="slist.OS"
                label="v"
                track-by="k"
                :show-labels="false"
                @select="onChange('OS', $event.k)"
              >
                {{ $t('fields.os') }}
              </base-select>
            </div>
          </div>
          <div class="l-col">
            <label class="l-col">{{ sendText }}</label>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
/* eslint-disable */
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { isArray } from 'lodash';
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import { format } from 'date-fns';
export default {
  name: 'ReinstallOS',
  components: {
    BaseSelect,
    BaseInput,
  },
  mixins: [setFocus],
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    model: {
      type: Object,
      default: () => {},
    },
    slist: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      os: Object.assign(
        {},
        this.slist.OS.find(x => x.k === this.fields.OS.value)
      ),
      isLoading: !!this.user,
      formData: {
        vmrros_date: '',
        new_pass: this.model.new_pass,
        elid: this.model.elid,
        OS: this.slist.OS.find(x => x.k === this.fields.OS.value).k,
      },
    };
  },
  computed: {
    currentDate() {
      return format(new Date(), 'ddMMyyyy');
    },
    sendText() {
      return this.fields.mlong.label.replace('ОК', 'Отправить');
    },
  },
  watch: {
    'formData.vmrros_date': function () {
      this.$emit('ready');
    },
  },
  mounted() {},
  methods: {
    showPassword(item) {
      if (item.type === 'password') {
        item.type = 'text';
      } else {
        item.type = 'password';
      }
    },
    isArray(item) {
      return isArray(item);
    },
    onChange(key, val) {
      this.formData[key] = val;
      this.$emit('change', this.formData);
      if (this.formData.vmrros_date) this.$emit('ready');
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "fields" : {
        "date" : "Проверочное поле",
        "os" : "Новая ОС",
        "pass" : "Новый пароль"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-settings-form {
  &__avatar-upload {
    margin: 0 auto 1.5rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }

    & + &--checkbox {
      +breakpoint(sm-and-up) {
        margin-top: 4rem;
      }
    }
  }
  &__footer {
    +breakpoint(sm-and-up) {
      margin: 1.5rem auto 0;
      flexy(flex-end, center);
    }
  }
  &__btn {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
    }
  }
}
</style>
